import { Box, Container, Typography, FormControl, InputLabel, Select, MenuItem, Paper, TextField, ListItemText, Card, CardContent, CardActions, Button, CardHeader, IconButton, CircularProgress, LinearProgress } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState } from 'react'
import { FixedSizeList } from 'react-window'
import axios from 'axios'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import UnrealDialog from './UnrealDialog'
import ShotChart from './ShotChart'

export default function AppLegacyShotCharts(props) {

  const [chartType, setChartType] = useState('Player')
  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [playerQuery, setPlayerQuery] = useState('')
  const [playerList, setPlayerList] = useState([])
  const [shotsInChart, setShotsInChart] = useState([])
  const [showShotChart, setShowShotChart] = useState(false)
  const [unrealDialogIsOpen, setUnrealDialogIsOpen] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [seasonsLoaded, setSeasonsLoaded] = useState(false)
  const [shotChartLoading, setShotChartLoading] = useState(false)
  const [progress, setProgress] = React.useState(0)

  let shotChartRef = React.useRef(null)

  const addToSelectedPlayers = (player) => {
    setSelectedPlayers([...selectedPlayers, player])
    setShowShotChart(false)
  }

  const removeFromSelectedPlayers = (player) => {
    setSelectedPlayers(oldValues => {
      return oldValues.filter(currentPlayer => currentPlayer !== player)
    })
    setShowShotChart(false)
  }

  const handleBuildPreviewChart = (event) => {
    setShotChartLoading(true)
    fetchShotChartData()
    setShowShotChart(true)
  }

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value)
    setShowShotChart(false)
  }

  const handleCloseUnrealDialog = (event) => {
    setUnrealDialogIsOpen(false)
  }

  const handleGameDateChange = (date, playerIndex) => {
    let players = selectedPlayers
    let currentPlayer = players[playerIndex]
    currentPlayer.selectedGameDate = date
    players[playerIndex] = currentPlayer
    setSelectedPlayers([...players])
    setShowShotChart(false)
  }

  const handleGameTypeChange = (event, playerIndex) => {
    let players = selectedPlayers
    let currentPlayer = players[playerIndex]
    currentPlayer.gameType = event.target.value
    players[playerIndex] = currentPlayer
    setSelectedPlayers([...players])
    setShowShotChart(false)
  }

  const handleSeasonChange = (event, playerIndex) => {
    let players = selectedPlayers
    let currentPlayer = players[playerIndex]
    currentPlayer.selectedSeason = event.target.value
    players[playerIndex] = currentPlayer
    setSelectedPlayers([...players])
    setShowShotChart(false)
  }

  const handleShotResultChange = (event, playerIndex) => {
    let players = selectedPlayers
    let currentPlayer = players[playerIndex]
    currentPlayer.shotResult = event.target.value
    players[playerIndex] = currentPlayer
    setSelectedPlayers([...players])
    setShowShotChart(false)
  }

  const handleShotTypeChange = (event, playerIndex) => {
    let players = selectedPlayers
    let currentPlayer = players[playerIndex]
    currentPlayer.shotType = event.target.value
    players[playerIndex] = currentPlayer
    setSelectedPlayers([...players])
    setShowShotChart(false)
  }

  const handleStartDateChange = (date, playerIndex) => {
    let players = selectedPlayers
    let currentPlayer = players[playerIndex]
    currentPlayer.selectedStartDate = date
    players[playerIndex] = currentPlayer
    setSelectedPlayers([...players])
    setShowShotChart(false)
  }

  const handleEndDateChange = (date, playerIndex) => {
    let players = selectedPlayers
    let currentPlayer = players[playerIndex]
    currentPlayer.selectedEndDate = date
    players[playerIndex] = currentPlayer
    setSelectedPlayers([...players])
    setShowShotChart(false)
  }

  const handleTimeframeChange = (event, playerIndex) => {
    let players = selectedPlayers
    let currentPlayer = players[playerIndex]
    currentPlayer.timeframe = event.target.value
    players[playerIndex] = currentPlayer
    setSelectedPlayers([...players])
    setShowShotChart(false)
  }

  const fetchPlayerList = async () => {
    const data = await axios.get(`${process.env.REACT_APP_FLASK_URL}/allplayers`)
    setPlayerList(data.data)
    setDataLoaded(true)
  }

  const fetchSeasonsWithShots = async (player) => {
    const data = await axios.get(`${process.env.REACT_APP_FLASK_URL}/seasons?playerid=` + player.playerId)
    player.allSeasons = data.data.seasons
    player.selectedSeason = player.allSeasons[player.allSeasons.length - 1]
    player.seasonsLoaded = true
    setSeasonsLoaded(true)
  }

  const fetchShotChartData = async () => {
    let allShots = []
    for (let i = 0; i < selectedPlayers.length; i++) {
      const player = selectedPlayers[i]

      // Build URL
      let url = `${process.env.REACT_APP_FLASK_URL}/shots?playerid=` + player.playerId

      // Timeframe
      if (player.timeframe === 'One Game') {
        url = url + '&gamedate=' + player.selectedGameDate.format('YYYYMMDD')
      }
      else if (player.timeframe === 'Season') {
        url = url + '&season=' + player.selectedSeason + '&gametype=' + player.gameType
      }
      else if (player.timeframe === 'Career') {
        url = url + '&gametype=regular'
      }
      else if (player.timeframe === 'Playoffs') {
        url = url + '&gametype=playoffs'
      }
      else if (player.timeframe === 'Career + Playoffs') {
        url = url + '&gametype=all'
      }
      else if (player.timeframe === 'Date Range') {
        url = url + '&startdate=' + player.selectedStartDate.format('YYYYMMDD') + '&enddate=' + player.selectedEndDate.format('YYYYMMDD')
      }

      // Shot type
      if (player.shotType === '3-PT') {
        url = url + '&shottype=3'
      }
      else if (player.shotType === '2-PT') {
        url = url + '&shottype=2'
      }

      // Shot result
      if (player.shotResult === 'Makes') {
        url = url + '&shotresult=makes'
      }
      else if (player.shotResult === 'Misses') {
        url = url + '&shotresult=misses'
      }

      const data = await axios.get(url)
      allShots = allShots.concat(data.data.shots)
    }
    setShotsInChart(allShots)
    shotChartRef.current?.scrollIntoView({ behavior: 'smooth' })
    setShotChartLoading(false)
  }

  const filteredPlayerList = playerList.filter(player => {
    if (playerQuery === '') {
      return ''
    } else if (player[2].toLowerCase().includes(playerQuery.toLowerCase())) {
      return player
    }
  })

  const Row = ({ index, style }) => (
    <MenuItem style={style} key={filteredPlayerList[index].playerId}
      onClick={
        (event) => {
          let newPlayer = filteredPlayerList[index]
          newPlayer.timeframe = 'One Game'
          newPlayer.selectedGameDate = dayjs('2023-02-19')
          newPlayer.selectedStartDate = dayjs('2023-02-19')
          newPlayer.selectedEndDate = dayjs('2023-02-19')
          newPlayer.gameType = 'regular'
          newPlayer.shotType = 'All'
          newPlayer.shotResult = 'All'
          newPlayer.seasonsLoaded = false
          fetchSeasonsWithShots(newPlayer)
          addToSelectedPlayers(filteredPlayerList[index])
          setPlayerQuery('')
        }
      }>
      <ListItemText>{filteredPlayerList[index].playerName}</ListItemText>
    </MenuItem>
  )

  React.useEffect(() => {
    fetchPlayerList()
  }, [])

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1))
    }, 333)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <Container maxWidth='sm'>

      <Typography variant='h4' sx={{ mt: 2, mb: 3 }}>
        NBA Shot Charts
      </Typography>

      {dataLoaded ?

        <Box>

          <FormControl fullWidth>
            <InputLabel id='type-label'>Chart Type</InputLabel>
            <Select
              labelId='type-label'
              id='type-select'
              value={chartType}
              label='Chart Type'
              onChange={handleChartTypeChange}
            >
              <MenuItem value={'Player'}>Player</MenuItem>
              {/* <MenuItem value={'Team'}>Team</MenuItem> */}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 3 }} fullWidth>
            <TextField
              autoComplete="off"
              placeholder='Search players...'
              onChange={event => setPlayerQuery(event.target.value)}
              value={playerQuery}
            />
            <Paper sx={{
              maxHeight: '500px',
              //overflowY: 'scroll',
              overflowX: 'hidden',
              boxShadow: '0px 2px 4px rgb(0 0 0 / 20%)'
            }}>
              <FixedSizeList
                height={filteredPlayerList.length > 9 ? 360 : filteredPlayerList.length * 36}
                itemSize={36}
                itemCount={filteredPlayerList.length}
              >
                {Row}
              </FixedSizeList>
            </Paper>
          </FormControl>

        </Box>

        :

        <Box sx={{ textAlign: 'center', ml: 'auto', mr: 'auto' }}>
          Database Loading<br />
          <LinearProgress sx={{ m: 3 }} variant="determinate" value={progress} />
        </Box>
      }

      {selectedPlayers.length > 0 ?
        <Card variant="outlined" sx={{ mt: 3, mb: 3 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Chart Data
            </Typography>

            {selectedPlayers.map((player, index) => {
              return <Card sx={{ mt: 3 }}>
                <CardHeader
                  action={
                    <IconButton onClick={() => { removeFromSelectedPlayers(player) }}>
                      <DeleteIcon />
                    </IconButton>
                  }
                  title={player.playerName}
                />
                <CardContent>

                  <Box sx={{ minWidth: 120 }}>

                    <FormControl sx={{ 'width': '100%' }}>
                      <InputLabel id="timeframe-label">Timeframe</InputLabel>
                      <Select
                        labelId="timeframe-label"
                        id="select-timeframe"
                        value={player.timeframe}
                        label="Timeframe"
                        onChange={(event) => { handleTimeframeChange(event, index) }}
                      >
                        <MenuItem value={'One Game'}>One Game</MenuItem>
                        <MenuItem value={'Season'}>Season</MenuItem>
                        <MenuItem value={'Career'}>Career (Regular Season)</MenuItem>
                        <MenuItem value={'Playoffs'}>Playoffs</MenuItem>
                        <MenuItem value={'Career + Playoffs'}>Career + Playoffs</MenuItem>
                        <MenuItem value={'Date Range'}>Date Range</MenuItem>
                      </Select>
                    </FormControl>

                    {player.timeframe === 'One Game' ?
                      <FormControl sx={{ 'width': '100%', mt: 3 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name='selectedGameDate'
                            label='Game Date'
                            value={player.selectedGameDate}
                            onChange={(date) => { handleGameDateChange(date, index) }}
                            renderInput={(params) => <TextField size='small' {...params} />}
                            minDate={dayjs('1996-11-01')}
                            maxDate={dayjs('2023-02-19')}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      : ''}

                    {player.timeframe === 'Season' ?
                      <Box sx={{ 'width': '100%', 'display': 'inline-block', mt: 3 }}>

                        {player.seasonsLoaded ?
                          <FormControl sx={{ 'width': '48%', mr: '3%' }}>
                            <InputLabel id="season-label">Season</InputLabel>
                            <Select
                              labelId="season-label"
                              id="select-season"
                              value={player.selectedSeason}
                              label='Season'
                              onChange={(event) => { handleSeasonChange(event, index) }}
                            >
                              {player.allSeasons.map((season, index) => {
                                return <MenuItem value={season}>{season}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                          :
                          <FormControl sx={{ 'width': '48%', mr: '3%' }}>
                            Seasons Loading <CircularProgress />
                          </FormControl>}

                        <FormControl sx={{ 'width': '49%' }}>
                          <InputLabel id="game-type-label">Type</InputLabel>
                          <Select
                            labelId="game-type-label"
                            id="select-game-type"
                            value={player.gameType}
                            label='Type'
                            onChange={(event) => { handleGameTypeChange(event, index) }}
                          >
                            <MenuItem value={'regular'}>Regular</MenuItem>
                            <MenuItem value={'playoffs'}>Playoffs</MenuItem>
                            <MenuItem value={'all'}>All</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      : ''}

                    {player.timeframe === 'Date Range' ?
                      <Box sx={{ 'width': '100%', 'display': 'inline-block', mt: 3 }}>
                        <FormControl sx={{ 'width': '48%', mr: '4%' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              name='selectedStartDate'
                              label='Start Date'
                              value={player.selectedStartDate}
                              onChange={(date) => { handleStartDateChange(date, index) }}
                              renderInput={(params) => <TextField size='small' {...params} />}
                              minDate={dayjs('1996-11-01')}
                              maxDate={dayjs('2023-02-19')}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ 'width': '48%' }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              name='selectedEndDate'
                              label='End Date'
                              value={player.selectedEndDate}
                              onChange={(date) => { handleEndDateChange(date, index) }}
                              renderInput={(params) => <TextField size='small' {...params} />}
                              minDate={dayjs('1996-11-01')}
                              maxDate={dayjs('2023-02-19')}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Box>
                      : ''}

                  </Box>

                  <Box sx={{ minWidth: 120, mt: 3 }}>
                    <FormControl fullWidth>
                      <InputLabel id="shot-type-label">Shot Type</InputLabel>
                      <Select
                        labelId="shot-type-label"
                        id="select-shot-type"
                        value={player.shotType}
                        label='Shot Type'
                        onChange={(event) => { handleShotTypeChange(event, index) }}
                      >
                        <MenuItem value={'All'}>All</MenuItem>
                        <MenuItem value={'3-PT'}>3-PT</MenuItem>
                        <MenuItem value={'2-PT'}>2-PT</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ minWidth: 120, mt: 3 }}>
                    <FormControl fullWidth>
                      <InputLabel id="shot-result-label">Shot Result</InputLabel>
                      <Select
                        labelId="shot-result-label"
                        id="select-shot-result"
                        value={player.shotResult}
                        label='Shot Result'
                        onChange={(event) => { handleShotResultChange(event, index) }}
                      >
                        <MenuItem value={'All'}>All</MenuItem>
                        <MenuItem value={'Makes'}>Makes</MenuItem>
                        <MenuItem value={'Misses'}>Misses</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                </CardContent>
              </Card>
            })}

          </CardContent>
          <CardActions>
            <Button variant="contained" disabled={shotChartLoading} onClick={handleBuildPreviewChart} sx={{ ml: 1, mb: 1 }}>Build Preview Chart
              {shotChartLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </Button>

            <Button variant="contained" color="success" onClick={() => { setUnrealDialogIsOpen(true) }} sx={{ mb: 1 }}>Send to Unreal</Button>
          </CardActions>
        </Card>
        : ''}

      <UnrealDialog open={unrealDialogIsOpen} handleClose={handleCloseUnrealDialog}></UnrealDialog>

      <Box ref={shotChartRef}>
        {showShotChart ? <ShotChart data={shotsInChart}></ShotChart> : ''}
      </Box>

    </Container>
  )
}
import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, FormControl, TextField, Paper, MenuItem, ListItemText, Card, CardHeader, CardContent, IconButton, InputLabel, Select, CircularProgress, CardActions } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { FixedSizeList } from 'react-window'
import axios from 'axios'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import RenderJobCard from './RenderJobCard'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import FT2KShotChart from './FT2KShotChart'

import ReactPlayer from 'react-player'
import { Storage } from "aws-amplify";

export default function FT2KCustomMode(props) {

  /* -------- Web socket functionality... move somewhere else -------- */
  useWebSocket(`${process.env.REACT_APP_WS_URL}`, {
    onOpen: () => {
      console.log('WebSocket connection established.')
    }
  }, [])

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(`${process.env.REACT_APP_WS_URL}`, {
    share: true
  })
  /* ----------------------------------------------------------------- */

  const [playerList, setPlayerList] = useState([])
  const [playerQuery, setPlayerQuery] = useState('')
  const [selectedPlayer, setSelectedPlayer] = useState('')
  const [seasonsLoaded, setSeasonsLoaded] = useState(false)
  const [showShotChart, setShowShotChart] = useState(false)
  const [shotChartLoading, setShotChartLoading] = useState(false)
  const [showRenderJob, setShowRenderJob] = useState(false)
  const [renderStatus, setRenderStatus] = useState('')
  const [renderFilename, setRenderFilename] = useState('')
  const [renderFileURL, setRenderFileURL] = useState('')

  const handleClickCancelRender = () => {
    sendJsonMessage({
      command: 'Cancel Job'
    })
  }

  const handleClickDownload = () => {
    const link = document.createElement('a')
    link.download = renderFilename
    link.href = renderFileURL
    link.setAttribute('content-disposition', 'attachment')
    link.click()
  }

  const handleClickEditChart = () => {
    setShowRenderJob(false)
  }

  const handleClickNewChart = () => {
    setShowRenderJob(false)
    setSelectedPlayer('')
  }

  const handleClickRender = () => {
    setRenderStatus('Render Options')
    setShowRenderJob(true)
  }

  const handleClickStartRender = (aspectRatio, cameraAngle) => {
    setRenderStatus('Sending to Unreal...')
    sendJsonMessage({
      command: 'Render',
      playerId: selectedPlayer[0],
      preExtractedShotsFolder: selectedPlayer[2].replace(/\s/g, '') + '_Free_Throws',
      aspectRatio: aspectRatio,
      cameraAngle: cameraAngle
    })
  }

  const handleGameDateChange = (date) => {
    setSelectedPlayer({
      ...selectedPlayer,
      selectedGameDate: date
    })
    setShowShotChart(false)
  }

  const handleGameTypeChange = (event) => {
    setSelectedPlayer({
      ...selectedPlayer,
      gameType: event.target.value
    })
    setShowShotChart(false)
  }

  const handleSeasonChange = (event) => {
    setSelectedPlayer({
      ...selectedPlayer,
      selectedSeason: event.target.value
    })
    setShowShotChart(false)
  }

  const handleShotResultChange = (event) => {
    setSelectedPlayer({
      ...selectedPlayer,
      shotResult: event.target.value
    })
    setShowShotChart(false)
  }

  const handleStartDateChange = (date) => {
    setSelectedPlayer({
      ...selectedPlayer,
      selectedStartDate: date
    })
    setShowShotChart(false)
  }

  const handleEndDateChange = (date) => {
    setSelectedPlayer({
      ...selectedPlayer,
      selectedEndDate: date
    })
    setShowShotChart(false)
  }

  const handleTimeframeChange = (event) => {
    setSelectedPlayer({
      ...selectedPlayer,
      timeframe: event.target.value
    })
    setShowShotChart(false)
  }

  const fetchPlayerList = async () => {
    const data = await axios.get(`${process.env.REACT_APP_FLASK_URL}/allplayers`)
    setPlayerList(data.data)
  }

  const fetchSeasonsWithShots = async (player) => {
    const data = await axios.get(`${process.env.REACT_APP_FLASK_URL}/seasons?playerid=` + player[0])
    player.allSeasons = data.data.seasons
    player.selectedSeason = player.allSeasons[player.allSeasons.length - 1]
    player.seasonsLoaded = true
    setSeasonsLoaded(true)
  }

  const filteredPlayerList = playerList.filter(player => {
    if (playerQuery === '') {
      return ''
    } else if (player[2].toLowerCase().includes(playerQuery.toLowerCase())) {
      return player
    }
  })

  const Row = ({ index, style }) => (
    <MenuItem style={style} key={filteredPlayerList[index][2]}
      onClick={
        (event) => {
          let newPlayer = filteredPlayerList[index]
          newPlayer.timeframe = 'One Game'
          newPlayer.selectedGameDate = dayjs('2023-04-02')
          newPlayer.selectedStartDate = dayjs('2023-04-02')
          newPlayer.selectedEndDate = dayjs('2023-04-02')
          newPlayer.gameType = 'regular'
          newPlayer.shotType = 'Free Throw'
          newPlayer.shotResult = 'All'
          newPlayer.seasonsLoaded = false
          fetchSeasonsWithShots(newPlayer)
          setSelectedPlayer(newPlayer)
          setPlayerQuery('')
        }
      }>
      <ListItemText>{filteredPlayerList[index][2]}</ListItemText>
    </MenuItem>
  )

  useEffect(() => {
    fetchPlayerList()
  }, [])

  const getS3File = async (filename) => {
    await Storage.get(filename, { validateObjectExistence: true })
      .then(result => {
        setRenderFileURL(result)
        setRenderStatus('Render Finished')
      })
      .catch(err => { 
        getS3File(filename)
      })
  }

  useEffect(() => {
    if (lastMessage !== null) {
      let msgArray = lastMessage.data.split('#')
      if (msgArray.length === 3) {
        if (msgArray[2].trim() === 'Rendering') {
          setRenderStatus('Rendering...')
        }
        else if (msgArray[2].trim() === 'Render Canceled') {
          setRenderStatus('Render Canceled')
        }
      }
      else if (msgArray.length === 4) {
        if (msgArray[2].trim() === 'Render Finished') {
          let filename = msgArray[3].trim()
          // wait until file has been uploaded to aws s3
          getS3File(filename)
        }
        setRenderFilename(msgArray[3].trim())
      }
    }
  }, [lastMessage])

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState]

  return (
    <Box sx={{ flexGrow: 1, mt: 2 }}>

      <Typography sx={{ fontSize: '1.2em', fontWeight: 100, fontFamily: 'Roboto', color: '#fff', mb: 2 }}>
        Custom Build Mode
        <Button color='info' variant='outlined' sx={{ ml: 2 }} onClick={(e) => { props.modeChangeHandler('') }}>
          Change Mode
        </Button>
      </Typography>

      {selectedPlayer === '' ?

        <FormControl sx={{ mt: 3 }}>
          <TextField
            autoComplete="off"
            placeholder='Search players...'
            onChange={event => setPlayerQuery(event.target.value)}
            value={playerQuery}
          />
          <Paper sx={{
            maxHeight: '500px',
            overflowX: 'hidden',
            boxShadow: '0px 2px 4px rgb(0 0 0 / 20%)'
          }}>
            <FixedSizeList
              height={filteredPlayerList.length > 9 ? 360 : filteredPlayerList.length * 36}
              itemSize={36}
              itemCount={filteredPlayerList.length}
            >
              {Row}
            </FixedSizeList>
          </Paper>
        </FormControl>

        :

        showRenderJob ?

          <div>
            <RenderJobCard renderStatus={renderStatus} startRender={handleClickStartRender} cancelRender={handleClickCancelRender} renderFilename={renderFilename} renderFileURL={renderFileURL} downloadVideo={handleClickDownload} />
            <CardActions sx={{ justifyContent: 'flex-start', mt: 2, mb: 3 }}>
              <Button variant='contained' color='info' sx={{ mb: 1, mr: 1 }} onClick={handleClickEditChart}><ArrowBackIosIcon fontSize='small' />EDIT CHART</Button>
              {renderStatus !== 'Render Options' ?
                <Button variant='contained' color='success' sx={{ mb: 1, mr: 1 }} onClick={handleClickNewChart}>NEW CHART</Button>
                : ''
              }
            </CardActions>
          </div>

          :

          <Box sx={{ display: 'flex' }}>
            <Card sx={{ mt: 3, width: '50%' }}>
              <CardHeader
                action={
                  <IconButton onClick={() => { setSelectedPlayer('') }}>
                    <ClearIcon />
                  </IconButton>
                }
                title={selectedPlayer[2]}
              />
              <CardContent>

                <Box sx={{ minWidth: 120 }}>

                  <FormControl sx={{ 'width': '100%' }}>
                    <InputLabel id='timeframe-label'>Timeframe</InputLabel>
                    <Select
                      labelId='timeframe-label'
                      id='select-timeframe'
                      value={selectedPlayer.timeframe}
                      label="Timeframe"
                      onChange={(event) => { handleTimeframeChange(event) }}
                    >
                      <MenuItem value={'One Game'}>One Game</MenuItem>
                      <MenuItem value={'Season'}>Season</MenuItem>
                      <MenuItem value={'Date Range'}>Date Range</MenuItem>
                    </Select>
                  </FormControl>

                  {selectedPlayer.timeframe === 'One Game' ?
                    <FormControl sx={{ 'width': '100%', mt: 3 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name='selectedGameDate'
                          label='Game Date'
                          value={selectedPlayer.selectedGameDate}
                          onChange={(date) => { handleGameDateChange(date) }}
                          renderInput={(params) => <TextField size='small' {...params} />}
                          minDate={dayjs('2023-04-02')}
                          maxDate={dayjs('2023-04-02')}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    : ''}

                  {selectedPlayer.timeframe === 'Season' ?
                    <Box sx={{ 'width': '100%', 'display': 'inline-block', mt: 3 }}>

                      {selectedPlayer.seasonsLoaded ?
                        <FormControl sx={{ 'width': '48%', mr: '3%' }}>
                          <InputLabel id="season-label">Season</InputLabel>
                          <Select
                            labelId="season-label"
                            id="select-season"
                            value={selectedPlayer.selectedSeason}
                            label='Season'
                            onChange={(event) => { handleSeasonChange(event) }}
                          >
                            {selectedPlayer.allSeasons.map((season, index) => {
                              return <MenuItem value={season}>{season}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                        :
                        <FormControl sx={{ 'width': '48%', mr: '3%' }}>
                          Seasons Loading <CircularProgress />
                        </FormControl>}

                      <FormControl sx={{ 'width': '49%' }}>
                        <InputLabel id="game-type-label">Type</InputLabel>
                        <Select
                          labelId='game-type-label'
                          id='select-game-type'
                          value={selectedPlayer.gameType}
                          label='Type'
                          onChange={(event) => { handleGameTypeChange(event) }}
                        >
                          <MenuItem value={'regular'}>Regular</MenuItem>
                          <MenuItem value={'playoffs'}>Playoffs</MenuItem>
                          <MenuItem value={'all'}>All</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    : ''}

                  {selectedPlayer.timeframe === 'Date Range' ?
                    <Box sx={{ 'width': '100%', 'display': 'inline-block', mt: 3 }}>
                      <FormControl sx={{ 'width': '48%', mr: '4%' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name='selectedStartDate'
                            label='Start Date'
                            value={selectedPlayer.selectedStartDate}
                            onChange={(date) => { handleStartDateChange(date) }}
                            renderInput={(params) => <TextField size='small' {...params} />}
                            minDate={dayjs('2023-04-02')}
                            maxDate={dayjs('2023-04-02')}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl sx={{ 'width': '48%' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name='selectedEndDate'
                            label='End Date'
                            value={selectedPlayer.selectedEndDate}
                            onChange={(date) => { handleEndDateChange(date) }}
                            renderInput={(params) => <TextField size='small' {...params} />}
                            minDate={dayjs('2023-04-02')}
                            maxDate={dayjs('2023-04-02')}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Box>
                    : ''}

                </Box>

                <Box sx={{ minWidth: 120, mt: 3 }}>
                  <FormControl fullWidth>
                    <InputLabel id='shot-result-label'>Result</InputLabel>
                    <Select
                      labelId='shot-result-label'
                      id='select-shot-result'
                      value={selectedPlayer.shotResult}
                      label='Result'
                      onChange={(event) => { handleShotResultChange(event) }}
                    >
                      <MenuItem value={'All'}>All Free Throws</MenuItem>
                      <MenuItem value={'Makes'}>Made Free Throws</MenuItem>
                      <MenuItem value={'Misses'}>Missed Free Throws</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

              </CardContent>

              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button variant='contained' disabled={shotChartLoading} sx={{ mb: 1 }}>Preview
                  {shotChartLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                      }}
                    />
                  )}
                </Button>
                <Button variant='contained' color='success' sx={{ mb: 1, mr: 1 }} onClick={handleClickRender}>Render</Button>
              </CardActions>

            </Card>
            <Card sx={{ mt: 3, width: '50%', height: '373px', ml: 1 }}>
              <FT2KShotChart data={''} />
            </Card>
          </Box>

      }

    </Box>
  )
}
import './App.css'
import React, { useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Container } from '@mui/material'
import Header from './components/Header'
import ProductMenu from './components/ProductMenu'
import AppAvatarShotCharts from './components/AppAvatarShotCharts'
import AppFT2K from './components/AppFT2K'
import AppLegacyShotCharts from './components/AppLegacyShotCharts'
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { configureAmplify } from './services'

Amplify.configure(awsExports);
configureAmplify();


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#e9d881'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    success: {
      // light: will be calculated from palette.primary.main,
      main: '#009150'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    info: {
      // light: will be calculated from palette.primary.main,
      main: '#eee'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  }
})

function App({ signOut, user }) {

  const [selectedProduct, setSelectedProduct] = useState('')

  const handleProductSelect = (newSelection) => {
    setSelectedProduct(newSelection)
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Header productClickHandler={handleProductSelect} signOutHandler={signOut} />
      <Container maxWidth='lg' sx={{ mt: 3 }} >
        {selectedProduct === '' ? <ProductMenu productClickHandler={handleProductSelect} /> :
          selectedProduct === 'Avatar Shot Charts' ? <AppAvatarShotCharts /> :
            selectedProduct === 'FT2K' ? <AppFT2K /> :
              selectedProduct === 'Legacy Shot Charts' ? <AppLegacyShotCharts /> : ''
        }
      </Container>
    </ThemeProvider>
  )
}

export default withAuthenticator(App, { hideSignUp: true })

import React, { useState } from 'react'
import { Box, Typography, Button, MenuItem, Card, CardActions, Select, InputLabel, FormControl, CardContent, TextField } from '@mui/material'
import useWebSocket, { ReadyState } from 'react-use-websocket'

export default function FT2KInGameMode(props) {

  /* -------- Web socket functionality... move somewhere else -------- */
  useWebSocket(`${process.env.REACT_APP_WS_URL}`, {
    onOpen: () => {
      console.log('WebSocket connection established.')
    }
  }, [])

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(`${process.env.REACT_APP_WS_URL}`, {
    share: true
  })
  /* ----------------------------------------------------------------- */

  const [pbpFeed, setPbpFeed] = useState('Waiting for connection...')
  const [feedConnected, setFeedConnected] = useState(false)

  const handleClickConnectFeed = () => {
    setPbpFeed('Connected')
    setFeedConnected(true)

    let feed = 'Connected'

    setTimeout(() => {
      feed = feed + '\nBooker 3PT Jump Shot (11 PTS) (Warren 1 AST)'
      setPbpFeed(feed)
    }, 1000)

    setTimeout(() => {
      feed = feed + '\nPayne S.FOUL (P2.T4) (D.Guthrie)'
      setPbpFeed(feed)
    }, 3000)

    setTimeout(() => {
      feed = feed + '\nGilgeous-Alexander Free Throw 1 of 2 (9 PTS)'
      setPbpFeed(feed)
    }, 5000)

    setTimeout(() => {
      feed = feed + '\nGilgeous-Alexander Free Throw 2 of 2 (10 PTS)'
      setPbpFeed(feed)
      sendJsonMessage({
        command: 'Play',
        playerId: 1628983,
        preExtractedShotsFolder: 'ShaiGilgeous-Alexander_Free_Throws',
        aspectRatio: '16:9',
        cameraAngle: 'Overhead'
      })
    }, 7000)

    setTimeout(() => {
      feed = feed + '\nMISS Booker 13\' Turnaround Jump Shot'
      setPbpFeed(feed)
    }, 9000)

    setTimeout(() => {
      feed = feed + '\nSuns Rebound'
      setPbpFeed(feed)
    }, 11000)

  }

  const handleClickDisconnectFeed = () => {
    setPbpFeed('Waiting for connection...')
    setFeedConnected(false)
  }

  return (
    <Box sx={{ flexGrow: 1, mt: 2 }}>

      <Typography sx={{ fontSize: '1.2em', fontWeight: 100, fontFamily: 'Roboto', color: '#fff', mb: 2 }}>
        Live In-Game Mode
        <Button color='info' variant='outlined' sx={{ ml: 2 }} onClick={(e) => { props.modeChangeHandler('') }}>
          Change Mode
        </Button>
      </Typography>

      <Box sx={{ display: 'flex' }}>

        <Card sx={{ mt: 3, width: '50%' }}>

          <CardContent>

            <Box sx={{ minWidth: 120, mt: 1 }}>
              <FormControl sx={{ 'width': '100%' }}>
                <InputLabel id='game-label'>Select Live Game</InputLabel>
                <Select
                  labelId='game-label'
                  id='select-game'
                  value={'Test Game'}
                  label='Select Live Game'
                //onChange={(event) => { handleTimeframeChange(event) }}
                >
                  <MenuItem value={'Test Game'}>Test Game</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 120, mt: 3 }}>
              <FormControl sx={{ 'width': '100%' }}>
                <InputLabel id='engine-label'>Select Unreal Engine</InputLabel>
                <Select
                  labelId='engine-label'
                  id='select-engine'
                  value={'Test Engine'}
                  label='Select Unreal Engine'
                //onChange={(event) => { handleTimeframeChange(event) }}
                >
                  <MenuItem value={'Test Engine'}>Test Engine</MenuItem>
                </Select>
              </FormControl>
            </Box>

          </CardContent>

          <CardActions sx={{ justifyContent: 'flex-end' }}>
            {feedConnected ?
              <Button variant='contained' color='error' sx={{ mb: 1, mr: 1 }} onClick={handleClickDisconnectFeed}>Disconnect Feed</Button>
              : <Button variant='contained' color='success' sx={{ mb: 1, mr: 1 }} onClick={handleClickConnectFeed}>Connect Feed</Button>
            }
          </CardActions>

        </Card>

        <Card sx={{ mt: 3, width: '50%', height: '240px', ml: 1 }}>
          <Box sx={{ minWidth: 120, mt: 2, ml: 2, mr: 2 }}>
            <Typography sx={{ fontSize: '1em', fontWeight: 100, fontFamily: 'Roboto', color: '#e9d881' }}>
              Output
            </Typography>
            <Box sx={{ overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
              <Box sx={{
                whiteSpace: 'pre-wrap',
                lineHeight: '2em', border: '1px solid gray',
                borderRadius: '4px', padding: '5px', mt: 1,
                overflow: 'auto', height: '172px', display: 'flex',
                flexDirection: 'column-reverse'
              }}>
                {pbpFeed}
              </Box>
            </Box>
          </Box>
        </Card>

      </Box >

    </Box >
  )
}
import { Card, CardMedia, CardContent, Typography } from '@mui/material'

export default function ProductCard(props) {
  return (
    <Card sx={{
      cursor: 'pointer', 
      '&:hover': {
        borderColor: '#e9d881',
        boxShadow: '0px 0px 8px rgba(212, 175, 55, 0.8), inset 0px 0px 8px rgba(212, 175, 55, 0.8)',
        borderWidth: '2px',
        borderStyle: 'solid'
      }
    }}>
      <CardMedia
        sx={{ height: 140 }}
        image={props.thumbnail}
      />
      <CardContent sx={{ paddingBottom: '16px !important' }}>
        <Typography sx={{ fontSize: '1.5em', fontWeight: 100, fontFamily: 'Roboto', color: '#e9d881' }}>
          {props.productName}
        </Typography>
      </CardContent>
    </Card >
  )
}
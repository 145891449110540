
import React, { useState } from 'react'
import { Typography } from '@mui/material'
import FT2KModeSelect from './FT2KModeSelect'
import FT2KCustomMode from './FT2KCustomMode'
import FT2KInGameMode from './FT2KInGameMode'

export default function AppFT2K(props) {

  const [selectedMode, setSelectedMode] = useState('')

  const handleModeSelect = (newSelection) => {
    setSelectedMode(newSelection)
  }

  return (
    <div>

      <Typography sx={{ fontSize: '1.8em', fontWeight: 100, fontFamily: 'Roboto', color: '#e9d881' }}>
        Free Throw 2000
      </Typography>

      {selectedMode === '' ? <FT2KModeSelect modeClickHandler={handleModeSelect} /> :
        selectedMode === 'Custom' ? <FT2KCustomMode modeChangeHandler={handleModeSelect} /> :
        selectedMode === 'In-Game' ? <FT2KInGameMode modeChangeHandler={handleModeSelect} /> : ''
      }

    </div>
  )

}
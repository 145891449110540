import { Box, Grid } from '@mui/material'
import ProductCard from './ProductCard'
import AvatarThumbnail from '../images/avatar-shot-chart.JPG'
import FT2KThumbnail from '../images/ft2k.JPG'
import LegacyThumbnail from '../images/legacy-shot-chart.JPG'

export default function ProductMenu(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={4} onClick={(e) => {props.productClickHandler('Avatar Shot Charts')}}>
          <ProductCard productName={'Avatar Shot Charts'} thumbnail={AvatarThumbnail} />
        </Grid>
        <Grid item xs={4} onClick={(e) => {props.productClickHandler('FT2K')}}>
          <ProductCard productName={'Free Throw 2000'} thumbnail={FT2KThumbnail} onClick={props.productClickHandler} />
        </Grid>
        <Grid item xs={4} onClick={(e) => {props.productClickHandler('Legacy Shot Charts')}}>
          <ProductCard productName={'Legacy Shot Charts'} thumbnail={LegacyThumbnail} onClick={props.productClickHandler} />
        </Grid>
      </Grid>
    </Box>
  )
}
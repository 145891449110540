import React, { useState } from 'react'
import { Card, CardActions, CardContent, Typography, Button, Box, FormControl, InputLabel, Select, MenuItem, LinearProgress } from '@mui/material'
import ReactPlayer from 'react-player'

export default function RenderJobCard(props) {

  const [cameraAngle, setCameraAngle] = useState('Overhead')
  const [aspectRatio, setAspectRatio] = useState('16:9')
  const [progress, setProgress] = React.useState(0)

  const handleAspectRatioChange = (event) => {
    setAspectRatio(event.target.value)
  }

  const handleCameraAngleChange = (event) => {
    setCameraAngle(event.target.value)
    if (event.target.value != 'Overhead') {
      setAspectRatio('16:9')
    }
  }

  React.useEffect(() => {
    if (props.renderStatus === 'Rendering...') {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1))
      }, 720)
      return () => {
        clearInterval(timer)
      }
    }
  }, [props.renderStatus])

  return (
    <Card sx={{ maxWidth: '600px', mt: 4 }}>
      <CardContent>
        <Typography sx={{ fontSize: '1.5em', fontWeight: 100, fontFamily: 'Roboto', color: '#e9d881' }} gutterBottom>
          {props.renderStatus}
        </Typography>

        {props.renderStatus === 'Render Options' ?
          <Box sx={{ minWidth: 120, mt: 3 }}>
            <FormControl sx={{ 'width': '100%' }}>
              <InputLabel id='camera-angle-label'>Camera Angle</InputLabel>
              <Select
                labelId='camera-angle-label'
                id='select-camera-angle'
                value={cameraAngle}
                label='Camera Angle'
                onChange={handleCameraAngleChange}
              >
                <MenuItem value={'Overhead'}>Overhead</MenuItem>
                <MenuItem value={'Left Side'}>Left Side</MenuItem>
                <MenuItem value={'Right Side'}>Right Side</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ 'width': '100%', mt: 3 }}>
              <InputLabel id='aspect-ratio-label'>Aspect Ratio</InputLabel>
              <Select
                labelId='aspect-ratio-label'
                id='select-aspect-ratio'
                value={aspectRatio}
                label='Aspect Ratio'
                onChange={handleAspectRatioChange}
              >
                <MenuItem value={'16:9'}>16:9</MenuItem>
                {cameraAngle === 'Overhead' ?
                  <MenuItem value={'9:16'}>9:16</MenuItem>
                  : ''
                }
              </Select>
            </FormControl>
          </Box>
          :
          props.renderStatus === 'Render Finished' ?
            <ReactPlayer url={props.renderFileURL} controls={true} width={'100%'} height={'100%'} />
            :
            props.renderStatus === 'Rendering...' ?
              <Box sx={{ minWidth: 120, mt: 3 }}>
                <LinearProgress sx={{ m: 3, height: 10, borderRadius: 5 }} variant="determinate" value={progress} />
              </Box>
              : ''
        }

      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {props.renderStatus === 'Render Finished' ?
          <span style={{ marginBottom: '10px', marginRight: '10px' }}>{props.renderFilename}</span>
          : ''}
        {props.renderStatus === 'Render Options' ?
          <Button variant='contained' color='success' sx={{ mb: 1, mr: 1 }} onClick={() => { props.startRender(aspectRatio, cameraAngle) }}>START RENDER</Button>
          :
          props.renderStatus === 'Render Canceled' ?
            <Button variant='contained' color='success' sx={{ mb: 1, mr: 1 }} onClick={() => { props.startRender(aspectRatio, cameraAngle) }}>RESTART RENDER</Button>
            :
            props.renderStatus === 'Render Finished' ?
              <Button variant='contained' color='success' sx={{ mb: 1, mr: 1 }} onClick={props.downloadVideo}>DOWNLOAD MP4</Button>
              :
              <Button variant='contained' color='error' sx={{ mb: 1, mr: 1 }} onClick={props.cancelRender}>CANCEL RENDER</Button>
        }
      </CardActions>
    </Card >
  )
}
import { Card, CardActions, CardContent, Typography, Button } from '@mui/material'

export default function ModeCard(props) {
  return (
    <Card sx={{
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#e9d881',
        boxShadow: '0px 0px 8px rgba(212, 175, 55, 0.8), inset 0px 0px 8px rgba(212, 175, 55, 0.8)',
        borderWidth: '2px',
        borderStyle: 'solid'
      }
    }}>
      <CardContent>
        <Typography sx={{ fontSize: '1.5em', fontWeight: 100, fontFamily: 'Roboto', color: '#e9d881' }} gutterBottom>
          {props.modeName}
        </Typography>
        {props.modeDetails}
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant='contained' color='success' onClick={() => {}} sx={{ mb: 1, mr: 1, color: '#fff' }}>START</Button>
      </CardActions>
    </Card >
  )
}
import Amplify from '@aws-amplify/core';

export function configureAmplify() {
  Amplify.configure(
    {
      Auth: {
        identityPoolId: 'us-east-2:c85e2dc1-36d1-4c67-bf40-4b93d535b3a6', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-2', // REQUIRED - Amazon Cognito Region
        userPoolId: 'us-east-2_A6TzfESFW', //OPTIONAL - Amazon Cognito User Pool ID
        //userPoolWebClientId: 'XX-XXXX-X_abcd1234', //OPTIONAL - Amazon Cognito Web Client ID
      },
      Storage: {
        bucket: 'renders-bucket',
        region: 'us-east-2',
        identityPoolId: 'us-east-2:c85e2dc1-36d1-4c67-bf40-4b93d535b3a6'
      }
    }
  );
} 
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material' 

export default function UnrealDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        {"No Unreal Engines Connected"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Someday you will be able to connect one in a settings menu...
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, FormControl, TextField, Paper, MenuItem, ListItemText, Card, CardHeader, CardContent, IconButton, InputLabel, Select, CircularProgress, CardActions, FormGroup, FormControlLabel, Checkbox, FormHelperText, OutlinedInput } from '@mui/material'
import { FixedSizeList } from 'react-window'
import ClearIcon from '@mui/icons-material/Clear'
import axios from 'axios'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import ShotChart from './ShotChart'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import RenderJobCard from './RenderJobCard'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

export default function AppAvatarShotCharts(props) {

  const [playerList, setPlayerList] = useState([])
  const [playerQuery, setPlayerQuery] = useState('')
  const [selectedPlayer, setSelectedPlayer] = useState('')
  const [seasonsLoaded, setSeasonsLoaded] = useState(false)
  const [shotsInChart, setShotsInChart] = useState([])
  const [showShotChart, setShowShotChart] = useState(false)
  const [shotChartLoading, setShotChartLoading] = useState(false)
  const [showRenderJob, setShowRenderJob] = useState(false)
  const [renderStatus, setRenderStatus] = useState('')
  const [renderFilename, setRenderFilename] = useState('')
  const [renderFileURL, setRenderFileURL] = useState('')
  const [selectedShotTypes, setSelectedShotTypes] = React.useState(['Field Goals'])
  const [selectedFgFilters, setSelectedFgFilters] = React.useState(['All'])
  const [selectedZones, setSelectedZones] = React.useState(['All'])
  const [disableFgFilters, setDisableFgFilters] = React.useState(true)
  const [disableZones, setDisableZones] = React.useState(true)

  let shotChartRef = React.useRef(null)

  const handleClickPreview = (event) => {
    setShotChartLoading(true)
    fetchShotChartData()
    setShowShotChart(true)
  }

  const handleEventChange = (event) => {
    setSelectedPlayer({
      ...selectedPlayer,
      event: event.target.value
    })
    resetShotChart()
  }

  const handleFgFiltersChange = (event) => {
    const {
      target: { value },
    } = event
    if (value.includes('All')) {
      setSelectedFgFilters(['All'])
      setDisableFgFilters(true)
    }
    else {
      setSelectedFgFilters(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      )
      setDisableFgFilters(false)
    }
    resetShotChart()
  }

  const handleGameDateChange = (date) => {
    setSelectedPlayer({
      ...selectedPlayer,
      selectedGameDate: date
    })
    resetShotChart()
  }

  const handleGameTypeChange = (event) => {
    setSelectedPlayer({
      ...selectedPlayer,
      gameType: event.target.value
    })
    resetShotChart()
  }

  const handleSeasonChange = (event) => {
    setSelectedPlayer({
      ...selectedPlayer,
      selectedSeason: event.target.value
    })
    resetShotChart()
  }

  const handleShotResultChange = (event) => {
    setSelectedPlayer({
      ...selectedPlayer,
      shotResult: event.target.value
    })
    resetShotChart()
  }

  const handleShotTypesChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedShotTypes(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
    resetShotChart()
  }

  const handleStartDateChange = (date) => {
    setSelectedPlayer({
      ...selectedPlayer,
      selectedStartDate: date
    })
    resetShotChart()
  }

  const handleEndDateChange = (date) => {
    setSelectedPlayer({
      ...selectedPlayer,
      selectedEndDate: date
    })
    resetShotChart()
  }

  const handleTimeframeChange = (event) => {
    setSelectedPlayer({
      ...selectedPlayer,
      timeframe: event.target.value
    })
    resetShotChart()
  }

  const handleZonesChange = (event) => {
    const {
      target: { value },
    } = event
    if (value.includes('All')) {
      setSelectedZones(['All'])
      setDisableZones(true)
    }
    else {
      setSelectedZones(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      )
      setDisableZones(false)
    }
    resetShotChart()
  }

  const filteredPlayerList = playerList.filter(player => {
    if (playerQuery === '') {
      return ''
    } else if (player[2].toLowerCase().includes(playerQuery.toLowerCase())) {
      return player
    }
  })

  const fetchPlayerList = async () => {
    const data = await axios.get(`${process.env.REACT_APP_FLASK_URL}/allplayers`)
    setPlayerList(data.data)
  }

  const fetchSeasonsWithShots = async (player) => {
    const data = await axios.get(`${process.env.REACT_APP_FLASK_URL}/seasons?playerid=` + player[0])
    player.allSeasons = data.data.seasons
    player.selectedSeason = player.allSeasons[player.allSeasons.length - 1]
    player.seasonsLoaded = true
    setSeasonsLoaded(true)
  }

  const fetchShotChartData = async () => {
    let allShots = []
    const player = selectedPlayer

    // Build URL
    let url = `${process.env.REACT_APP_FLASK_URL}/shots?playerid=` + player[0]

    // Timeframe
    if (player.timeframe === 'One Game') {
      url = url + '&gamedate=' + player.selectedGameDate.format('YYYYMMDD')
    }
    else if (player.timeframe === 'Season') {
      url = url + '&season=' + player.selectedSeason + '&gametype=' + player.gameType
    }
    else if (player.timeframe === 'Career') {
      url = url + '&gametype=regular'
    }
    else if (player.timeframe === 'Playoffs') {
      url = url + '&gametype=playoffs'
    }
    else if (player.timeframe === 'Career + Playoffs') {
      url = url + '&gametype=all'
    }
    else if (player.timeframe === 'Date Range') {
      url = url + '&startdate=' + player.selectedStartDate.format('YYYYMMDD') + '&enddate=' + player.selectedEndDate.format('YYYYMMDD')
    }

    // Shot type
    if (player.shotType === '3-PT') {
      url = url + '&shottype=3'
    }
    else if (player.shotType === '2-PT') {
      url = url + '&shottype=2'
    }

    // Shot result
    if (player.shotResult === 'Makes') {
      url = url + '&shotresult=makes'
    }
    else if (player.shotResult === 'Misses') {
      url = url + '&shotresult=misses'
    }

    const data = await axios.get(url)
    allShots = allShots.concat(data.data.shots)

    setShotsInChart(allShots)
    shotChartRef.current?.scrollIntoView({ behavior: 'smooth' })
    setShotChartLoading(false)
  }

  const resetFilters = () => {
    setSelectedShotTypes(['Field Goals'])
    setSelectedFgFilters(['All'])
    setSelectedZones(['All'])
    setDisableFgFilters(true)
    setDisableZones(true)
  }

  const resetShotChart = () => {
    setShowShotChart(false)
    setShotsInChart([])
  }

  const Row = ({ index, style }) => (
    <MenuItem style={style} key={filteredPlayerList[index][2]}
      onClick={
        (event) => {
          let newPlayer = filteredPlayerList[index]
          newPlayer.timeframe = 'One Game'
          newPlayer.selectedGameDate = dayjs('2023-04-02')
          newPlayer.selectedStartDate = dayjs('2023-04-02')
          newPlayer.selectedEndDate = dayjs('2023-04-02')
          newPlayer.gameType = 'regular'
          newPlayer.event = 'Shots'
          newPlayer.shotTypes = 'Field Goals'
          newPlayer.shotResult = 'All'
          newPlayer.seasonsLoaded = false
          fetchSeasonsWithShots(newPlayer)
          setSelectedPlayer(newPlayer)
          setPlayerQuery('')
          resetFilters()
          resetShotChart()
        }
      }>
      <ListItemText>{filteredPlayerList[index][2]}</ListItemText>
    </MenuItem>
  )

  const shotTypeOptions = ['Field Goals', 'Free Throws']
  const fgFilterOptions = ['All', 'Dunks', 'Jumpshots', 'One Foot', 'Assisted', 'Unassisted']
  const zoneOptions = ['All', '3-PT', '2-PT', 'Paint', 'Restricted Area', 'Midrange']

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  useEffect(() => {
    fetchPlayerList()
  }, [])

  return (
    <Box sx={{ flexGrow: 1, mt: 2 }}>
      <Typography sx={{ fontSize: '1.8em', fontWeight: 100, fontFamily: 'Roboto', color: '#e9d881' }}>
        Avatar Shot Charts
      </Typography>

      {selectedPlayer === '' ?

        <FormControl sx={{ mt: 3 }}>
          <TextField
            autoComplete="off"
            placeholder='Search players...'
            onChange={event => setPlayerQuery(event.target.value)}
            value={playerQuery}
          />
          <Paper sx={{
            maxHeight: '500px',
            overflowX: 'hidden',
            boxShadow: '0px 2px 4px rgb(0 0 0 / 20%)'
          }}>
            <FixedSizeList
              height={filteredPlayerList.length > 9 ? 360 : filteredPlayerList.length * 36}
              itemSize={36}
              itemCount={filteredPlayerList.length}
            >
              {Row}
            </FixedSizeList>
          </Paper>
        </FormControl>

        :

        <Box sx={{ display: 'flex' }}>
          <Card sx={{ mt: 3, mb: 6, width: '50%', height: 'fit-content' }}>
            <CardHeader
              action={
                <IconButton onClick={() => { setSelectedPlayer('') }}>
                  <ClearIcon />
                </IconButton>
              }
              title={selectedPlayer[2]}
            />
            <CardContent>

              <Box sx={{ minWidth: 120 }}>

                <FormControl sx={{ 'width': '100%' }}>
                  <InputLabel id='timeframe-label'>Timeframe</InputLabel>
                  <Select
                    labelId='timeframe-label'
                    id='select-timeframe'
                    value={selectedPlayer.timeframe}
                    label="Timeframe"
                    onChange={(event) => { handleTimeframeChange(event) }}
                  >
                    <MenuItem value={'One Game'}>One Game</MenuItem>
                    <MenuItem value={'Season'}>Season</MenuItem>
                    <MenuItem value={'Date Range'}>Date Range</MenuItem>
                  </Select>
                </FormControl>

                {selectedPlayer.timeframe === 'One Game' ?
                  <FormControl sx={{ 'width': '100%', mt: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name='selectedGameDate'
                        label='Game Date'
                        value={selectedPlayer.selectedGameDate}
                        onChange={(date) => { handleGameDateChange(date) }}
                        renderInput={(params) => <TextField size='small' {...params} />}
                        minDate={dayjs('2023-04-02')}
                        maxDate={dayjs('2023-04-02')}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  : ''}

                {selectedPlayer.timeframe === 'Season' ?
                  <Box sx={{ 'width': '100%', 'display': 'inline-block', mt: 3 }}>

                    {selectedPlayer.seasonsLoaded ?
                      <FormControl sx={{ 'width': '48%', mr: '3%' }}>
                        <InputLabel id="season-label">Season</InputLabel>
                        <Select
                          labelId="season-label"
                          id="select-season"
                          value={selectedPlayer.selectedSeason}
                          label='Season'
                          onChange={(event) => { handleSeasonChange(event) }}
                        >
                          {selectedPlayer.allSeasons.map((season, index) => {
                            return <MenuItem value={season}>{season}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                      :
                      <FormControl sx={{ 'width': '48%', mr: '3%' }}>
                        Seasons Loading <CircularProgress />
                      </FormControl>}

                    <FormControl sx={{ 'width': '49%' }}>
                      <InputLabel id="game-type-label">Type</InputLabel>
                      <Select
                        labelId='game-type-label'
                        id='select-game-type'
                        value={selectedPlayer.gameType}
                        label='Type'
                        onChange={(event) => { handleGameTypeChange(event) }}
                      >
                        <MenuItem value={'regular'}>Regular</MenuItem>
                        <MenuItem value={'playoffs'}>Playoffs</MenuItem>
                        <MenuItem value={'all'}>All</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  : ''}

                {selectedPlayer.timeframe === 'Date Range' ?
                  <Box sx={{ 'width': '100%', 'display': 'inline-block', mt: 3 }}>
                    <FormControl sx={{ 'width': '48%', mr: '4%' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name='selectedStartDate'
                          label='Start Date'
                          value={selectedPlayer.selectedStartDate}
                          onChange={(date) => { handleStartDateChange(date) }}
                          renderInput={(params) => <TextField size='small' {...params} />}
                          minDate={dayjs('2023-04-02')}
                          maxDate={dayjs('2023-04-02')}
                        />
                      </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ 'width': '48%' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name='selectedEndDate'
                          label='End Date'
                          value={selectedPlayer.selectedEndDate}
                          onChange={(date) => { handleEndDateChange(date) }}
                          renderInput={(params) => <TextField size='small' {...params} />}
                          minDate={dayjs('2023-04-02')}
                          maxDate={dayjs('2023-04-02')}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Box>
                  : ''}

              </Box>

              <Box sx={{ minWidth: 120, mt: 3 }}>
                <FormControl fullWidth>
                  <InputLabel id='event-label'>Event</InputLabel>
                  <Select
                    labelId='event-label'
                    id='select-event'
                    value={selectedPlayer.event}
                    label='Event'
                    onChange={(event) => { handleEventChange(event) }}
                  >
                    <MenuItem value={'Shots'}>Shots</MenuItem>
                    <MenuItem value={'Assists'}>Assists</MenuItem>
                    <MenuItem value={'Rebounds'}>Rebounds</MenuItem>
                    <MenuItem value={'Fouls'}>Fouls</MenuItem>
                    <MenuItem value={'Turnovers'}>Turnovers</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {selectedPlayer.event === 'Shots' ?
                <Box sx={{ minWidth: 120, mt: 3 }}>

                  <FormControl fullWidth>
                    <InputLabel id='shot-types-label'>Shot Types</InputLabel>
                    <Select
                      labelId='shot-types-label'
                      id='shot-types-select'
                      multiple
                      value={selectedShotTypes}
                      onChange={handleShotTypesChange}
                      input={<OutlinedInput label="Shot Types" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {shotTypeOptions.map((type) => (
                        <MenuItem key={type} value={type}>
                          <Checkbox checked={selectedShotTypes.indexOf(type) > -1} />
                          <ListItemText primary={type} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {selectedShotTypes.indexOf('Field Goals') > -1 ?
                    <Box>
                      <FormControl sx={{ mt: 3, mr: '2%', width: '49%' }}>
                        <InputLabel id='fg-filters-label'>Field Goal Filters</InputLabel>
                        <Select
                          labelId='fg-filters-label'
                          id='fg-filters-select'
                          multiple
                          value={selectedFgFilters}
                          onChange={handleFgFiltersChange}
                          input={<OutlinedInput label='Field Goal Filters' />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {fgFilterOptions.map((type, index) => (
                            <MenuItem key={type} value={type} sx={disableFgFilters && index > 0 ? { pointerEvents: "none" } : ''}>
                              <Checkbox checked={selectedFgFilters.indexOf(type) > -1} disabled={index > 0 && disableFgFilters ? true : false} />
                              <ListItemText primary={type} sx={disableFgFilters && index > 0 ? { opacity: '0.5' } : ''} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl sx={{ mt: 3, width: '49%' }}>
                        <InputLabel id='zones-label'>Zones</InputLabel>
                        <Select
                          labelId='zones-label'
                          id='zones-select'
                          multiple
                          value={selectedZones}
                          onChange={handleZonesChange}
                          input={<OutlinedInput label='Zones' />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {zoneOptions.map((type, index) => (
                            <MenuItem key={type} value={type} sx={disableZones && index > 0 ? { pointerEvents: "none" } : ''}>
                              <Checkbox checked={selectedZones.indexOf(type) > -1} disabled={index > 0 && disableZones ? true : false} />
                              <ListItemText primary={type} sx={disableZones && index > 0 ? { opacity: '0.5' } : ''} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    : ''
                  }

                  <Box sx={{ minWidth: 120, mt: 3 }}>
                    <FormControl fullWidth>
                      <InputLabel id='shot-result-label'>Result</InputLabel>
                      <Select
                        labelId='shot-result-label'
                        id='select-shot-result'
                        value={selectedPlayer.shotResult}
                        label='Result'
                        onChange={(event) => { handleShotResultChange(event) }}
                      >
                        <MenuItem value={'All'}>All</MenuItem>
                        <MenuItem value={'Makes'}>Makes</MenuItem>
                        <MenuItem value={'Misses'}>Misses</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                </Box>
                : ''
              }

            </CardContent>

            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button variant='contained' disabled={shotChartLoading} onClick={handleClickPreview} sx={{ mb: 1 }}>Preview
                {shotChartLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px'
                    }}
                  />
                )}
              </Button>
              <Button variant='contained' color='success' sx={{ mb: 1, mr: 1 }}>Render</Button>
            </CardActions>

          </Card>
          <Card sx={{ mt: 3, width: '50%', height: '536px', ml: 1 }}>
            <Box ref={shotChartRef}>
              <ShotChart data={shotsInChart} enabled={showShotChart} />
            </Box>
          </Card>
        </Box>

      }

    </Box>
  )
}
import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useD3 } from '../hooks/useD3'
import CourtLines from '../images/nba-court.svg'

export default function ShotChart(props) {

  const data = props.data
  const courtWidth = 500
  const courtHeight = 470
  const markerWidth = 12
  const markerHeight = 12
  const xOffset = courtWidth / 2 - markerWidth / 2
  const yOffset = 0.113 * courtHeight - markerHeight / 2
  const xScaleFactor = courtWidth / (500) // data court width = 500
  const yScaleFactor = courtHeight / (470 + 5) // data court height = 470

  const ref = useD3(
    (svg) => {

      svg.select('#makes').selectAll('*').remove()
      svg.select('#misses').selectAll('*').remove()

      for (let i = 0; i < data.length; i++) {
        if (data[i].shotMadeFlag === 1) {
          svg.select('#makes')
            .append('circle')
            .attr('r', markerWidth / 2)
            .attr('cx', data[i].locX * xScaleFactor + xOffset)
            .attr('cy', data[i].locY * yScaleFactor + yOffset)
            .attr('fill', '#39FF14')
            //.attr('style', 'filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.2)); -webkit-filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.2))')
            .attr('filter', 'url(#shadow)')
        }
        else if (data[i].shotMadeFlag === 0) {
          svg.select('#misses')
            .append('circle')
            .attr('r', markerWidth / 2)
            .attr('cx', data[i].locX * xScaleFactor + xOffset)
            .attr('cy', data[i].locY * yScaleFactor + yOffset)
            .attr('fill', 'none')
            .attr('stroke', '#fff')
            .attr('stroke-width', '2')
        }
      }
    },
    [data, props.timeframe]
  )

  return (
    <Box sx={{ mb: 4, width: '100%', mr: 'auto', ml: 'auto' }}>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <svg style={{
          height: '100%',
          width: '100%'
        }}
          viewBox={'0 0 451 424'}
        >
          <defs>
            <pattern id="courtWoodPattern" patternUnits="userSpaceOnUse" width="128" height="128">
              <image href={process.env.PUBLIC_URL + '/images/wood_light_2.png'} x="0" y="0" width="128" height="128" />
            </pattern>
            <filter id="shadow" x="0" y="0" width="120%" height="120%">
              <feDropShadow dx="1" dy="1" stdDeviation="0.25" flood-color="#000000" flood-opacity="0.5" />
            </filter>
          </defs>
          <rect style={{ height: '100%', width: '100%' }} fill="url(#courtWoodPattern)" />
        </svg>
        <svg style={{
          position: 'absolute',
          top: '-10',
          left: '-10'
        }}
          viewBox={'0 0 4000 3755'}>
          <image style={{ width: '100%', transformOrigin: 'top center', transform: 'scale(1.25)' }} href={CourtLines} />
        </svg>
        <svg
          ref={ref}
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 5,
            left: 8,
            overflow: 'visible'
          }}
          viewBox={'0 0 503 471'}
        >
          <g id='misses'></g>
          <g id='makes'></g>
        </svg>
      </Box>
    </Box>
  )
}
import { AppBar, Box, Container, Toolbar, Typography, Button } from '@mui/material'

export default function Header(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='relative'
        sx={{ zIndex: 1 }}>
        <Container maxWidth='lg'>
          <Toolbar sx={{ paddingLeft: '10px !important', paddingRight: '10px !important' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant='h4'
                noWrap
                component='div'
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, fontFamily: 'urbane', fontWeight: '700', cursor: 'pointer', lineHeight: '1em', marginTop: '6px', marginBottom: '1px' }}
                onClick={(e) => {props.productClickHandler('')}}>
                VIVID ARCS
              </Typography>
            </Box>
            <Button sx={{ position: 'absolute', right: '0' }} variant='outlined' onClick={props.signOutHandler}>Sign out</Button>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}
import { Box, Grid, Typography } from '@mui/material'
import ModeCard from './ModeCard'

export default function FT2KModeSelect(props) {
  return (
    <Box sx={{ flexGrow: 1, mt: 2 }}>
      <Typography sx={{ fontSize: '1.2em', fontWeight: 100, fontFamily: 'Roboto', color: '#fff', mb: 2 }}>
        Select mode:
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={4} onClick={(e) => { props.modeClickHandler('Custom') }}>
          <ModeCard modeName={'Custom Build'}
            modeDetails={'Build a custom free throw chart for any player since the 2023-24 season.'}
          />
        </Grid>
        <Grid item xs={4} onClick={(e) => { props.modeClickHandler('In-Game') }}>
          <ModeCard modeName={'Live In-Game'}
            modeDetails={'Select a game and connect to an Unreal Engine for auto-rendered charts.'}
          />
        </Grid>
      </Grid>
    </Box>
  )
}